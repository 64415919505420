import '@reach/dialog/styles.css';

import React from 'react';
import { Dialog as ReachDialog } from '@reach/dialog';
import styled from 'styled-components';

interface Props {
  visible: boolean;
  onHide(): void;
  children: React.ReactNode;
}

const Dialog: React.FC<Props> = (props) => {
  const { visible, onHide, children } = props;

  return (
    <ReachDialog isOpen={visible} onDismiss={onHide}>
      <CloseContainer>
        <Close onClick={onHide}>×</Close>
      </CloseContainer>

      {children}
    </ReachDialog>
  );
};

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Close = styled.button`
  appearance: none;
  border: none;
  font-size: 1.75rem;
  padding: 0;
  cursor: pointer;
  background-color: white;
`;

export default Dialog;
