import React from 'react';
import styled from 'styled-components';

interface Props {
  value: number;
}

const Progress: React.FC<Props> = (props) => {
  const { value } = props;

  return (
    <Container>
      <Bar
        style={{ width: `${value}%` }}
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={100}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
`;

const Bar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
`;

export default Progress;
