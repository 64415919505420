import './Description.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';

import { HomeDescriptionQuery } from 'components/home/__generated__/HomeDescriptionQuery';

const HOME_DESCRIPTION_QUERY = gql`
  query HomeDescriptionQuery {
    videoSearch {
      totalCount
    }
    partnersList {
      totalCount
    }
  }
`;

const CollectionSummary: React.FC = () => {
  return (
    <Query<HomeDescriptionQuery> query={HOME_DESCRIPTION_QUERY}>
      {({ data }) => {
        if (data == null) {
          return null;
        }

        return (
          <section className="HomeDescription">
            <div className="HomeDescription-messaging">
              MUSAIC is a library of{' '}
              <Link
                to="/search?page=1"
                className="HomeDescription-messaging-link"
              >
                {data.videoSearch?.totalCount} videos
              </Link>{' '}
              collaboratively managed by{' '}
              <Link
                to="/pages/about"
                className="HomeDescription-messaging-link"
              >
                {data.partnersList?.totalCount} schools
              </Link>{' '}
              of music from all over the world.
            </div>
            <Link to="/pages/about" className="HomeDescription-link">
              Learn More About MUSAIC
            </Link>
          </section>
        );
      }}
    </Query>
  );
};

export default CollectionSummary;
