import { gql } from '@apollo/client';

export type {
  CurrentUserQuery,
  CurrentUserQuery_currentUser as User
} from './__generated__/CurrentUserQuery';

export const CURRENT_USER_FIELDS = gql`
  fragment CurrentUserFields on User {
    id
    roles
    playlistAccess
    firstName
    lastName
    email
    yearOfBirth
    institution
    institutionOther
    instrument
    skillLevel
    careerGoal
    receiveNewsletter

    partner {
      id
    }
  }
`;

export const CURRENT_USER_QUERY = gql`
  query CurrentUserQuery {
    currentUser {
      ...CurrentUserFields
    }
  }

  ${CURRENT_USER_FIELDS}
`;
