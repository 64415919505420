import './LiveEventFooter.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { gql, useQuery } from '@apollo/client';

import CategoryTile from './CategoryTile';

import { ActiveLiveEventQuery } from './__generated__/ActiveLiveEventQuery';

const ACTIVE_LIVE_EVENT_QUERY = gql`
  query ActiveLiveEventQuery {
    liveEventsSummary {
      currentTotalCount
      futureTotalCount
      pastTotalCount
    }

    currentLiveEvents(first: 1) {
      id
      title
      description
      startDate
      endDate
    }
  }
`;

interface Props {}

const eventIsActive = (event: { startDate: string; endDate: string }) => {
  const startDate = DateTime.fromISO(event.startDate);
  const endDate = DateTime.fromISO(event.endDate);

  return startDate.toJSDate() <= new Date() && endDate.toJSDate() >= new Date();
};

const LiveEventFooter: React.FC<Props> = () => {
  const { data, loading } = useQuery<ActiveLiveEventQuery>(
    ACTIVE_LIVE_EVENT_QUERY
  );

  if (loading || data?.currentLiveEvents == null) {
    return null;
  }

  const event = data.currentLiveEvents[0];

  return (
    <section>
      {event != null ? (
        <div className="LiveEventFooter">
          <div className="LiveEventFooter-event">
            <CategoryTile
              to="/search?query=Live+Events"
              categoryName="Live Events"
            />

            <div className="LiveEventFooter-event-info">
              <div>Upcoming Event</div>

              <div className="LiveEventFooter-event-info-title">
                {event.title}
              </div>

              <div>
                {eventIsActive(event) ? (
                  <>
                    Ends at {DateTime.fromISO(event.endDate).toFormat('t ZZZZ')}
                  </>
                ) : (
                  <>
                    {DateTime.fromISO(event.startDate).toFormat('MMMM d, t')} -{' '}
                    {DateTime.fromISO(event.endDate).toFormat('t ZZZZ')}
                  </>
                )}
              </div>

              {event.description != null && (
                <div
                  className="LiveEventFooter-event-info-description"
                  dangerouslySetInnerHTML={{
                    __html: event.description
                  }}
                ></div>
              )}

              {eventIsActive(event) && (
                <Link
                  to={`/live-events/${event.id}`}
                  target="_blank"
                  className="LiveEventFooter-event-info-link"
                >
                  Watch Now
                </Link>
              )}

              {DateTime.fromISO(event.startDate).toJSDate() > new Date() && (
                <Link
                  to={`/live-events/${event.id}.ics`}
                  target="_blank"
                  className="LiveEventFooter-event-info-link"
                >
                  Add to Calendar
                </Link>
              )}
            </div>
          </div>

          <LiveEventCategoryLink
            eventCount={data.liveEventsSummary.pastTotalCount}
          />
        </div>
      ) : (
        <div className="LiveEventFooter">
          <Link
            className="LiveEventFooter-archiveLink"
            to="/pages/future-live-events"
          >
            <div className="LiveEventFooter-archiveLink-heading">
              No Upcoming Live Events
            </div>

            <div className="LiveEventFooter-archiveLink-reminder">
              View Full Calendar of Events
            </div>
          </Link>

          <LiveEventCategoryLink
            eventCount={data.liveEventsSummary.pastTotalCount}
          />
        </div>
      )}
    </section>
  );
};

const LiveEventCategoryLink: React.FC<{ eventCount?: number }> = (props) => {
  const { eventCount } = props;

  return (
    <Link
      className="LiveEventFooter-archiveLink"
      to="/search?query=Live+Events"
    >
      <div className="LiveEventFooter-archiveLink-heading">
        {eventCount ?? 0} Recorded Live Events
      </div>

      <div className="LiveEventFooter-archiveLink-reminder">
        View All Past Live Events
      </div>
    </Link>
  );
};

export default LiveEventFooter;
