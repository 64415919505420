import './UserMenu.scss';

import React, { useState, useRef } from 'react';
import TetherComponent from 'react-tether';
import { Link } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';
import { gql } from '@apollo/client';

import useClickOutside from 'utils/useClickOutside';

import {
  DestroySessionMutation,
  DestroySessionMutationVariables
} from './__generated__/DestroySessionMutation';

import {
  CURRENT_USER_QUERY,
  CurrentUserQuery,
  User
} from './queries/CurrentUserQuery';

import userBackground from 'assets/images/user-avatar.png';

const DESTROY_SESSION_MUATION = gql`
  mutation DestroySessionMutation($input: DestroySessionInput!) {
    destroySession(input: $input) {
      error
    }
  }
`;

interface Props {
  user: User;
}

const UserMenu: React.FC<Props> = (props) => {
  const { user } = props;
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLElement>();
  const menuRef = useRef<HTMLElement>();

  useClickOutside(
    [buttonRef, menuRef],
    () => {
      setIsOpen(false);
    },
    isOpen
  );

  const closeMenu = () => setIsOpen(false);

  return (
    <Mutation<DestroySessionMutation, DestroySessionMutationVariables>
      mutation={DESTROY_SESSION_MUATION}
      update={(cache, { data }) => {
        if (
          data != null &&
          data.destroySession != null &&
          data.destroySession.error == null
        ) {
          cache.writeQuery<CurrentUserQuery>({
            query: CURRENT_USER_QUERY,
            data: {
              currentUser: null
            }
          });
        }
      }}
    >
      {(destroySession) => (
        <TetherComponent
          attachment="top right"
          targetAttachment="bottom right"
          offset="-4px 0"
          constraints={[
            {
              to: 'scrollParent',
              attachment: 'together'
            }
          ]}
          renderTarget={(ref: React.MutableRefObject<HTMLElement>) => (
            <button
              data-test-id="user-menu-button"
              className="UserMenu"
              ref={(element) => {
                if (element != null) {
                  ref.current = element;
                }

                buttonRef.current = element ?? undefined;
              }}
              style={{
                backgroundImage: `url(${userBackground})`
              }}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              Account
            </button>
          )}
          renderElement={(ref: React.MutableRefObject<HTMLDivElement>) =>
            isOpen && (
              <div
                className="UserMenu-nav"
                ref={(element) => {
                  if (element != null) {
                    ref.current = element;
                  }

                  menuRef.current = element ?? undefined;
                }}
              >
                {user.partner != null && (
                  <>
                    <Link
                      className="UserMenu-nav-link"
                      to={`/partners/${user.partner.id}/videos`}
                      onClick={closeMenu}
                    >
                      Partner Videos
                    </Link>

                    <Link
                      className="UserMenu-nav-link"
                      to={`/partners/${user.partner.id}`}
                      onClick={closeMenu}
                    >
                      Partner Information
                    </Link>
                  </>
                )}

                <Link
                  to="/account"
                  className="UserMenu-nav-link"
                  onClick={closeMenu}
                >
                  Account
                </Link>

                {(user.roles.includes('admin') || user.playlistAccess) && (
                  <Link
                    className="UserMenu-nav-link"
                    to="/playlists"
                    onClick={closeMenu}
                  >
                    Playlists
                  </Link>
                )}

                {user.roles.includes('admin') && (
                  <a className="UserMenu-nav-link" href="/management">
                    Admin
                  </a>
                )}

                <button
                  className="UserMenu-nav-signOut"
                  onClick={() => {
                    destroySession({ variables: { input: {} } });
                    closeMenu();
                  }}
                >
                  Sign Out
                </button>
              </div>
            )
          }
        />
      )}
    </Mutation>
  );
};

export default UserMenu;
