import React from 'react';

interface Props {
  children: React.ReactNode;
  FallbackComponent: React.ComponentType<{ error?: Error | null }>;
}

interface State {
  error?: Error | null;
}

/*
 * This is rendered if Bugsnag JS is disabled or fails to load for some reason.
 */
class ErrorBoundary extends React.Component<Props, State> {
  static displayName = 'FallbackErrorBoundary';
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  state = { error: null };

  render() {
    const { FallbackComponent, children } = this.props;
    const { error } = this.state;

    if (error != null) {
      return <FallbackComponent error={error} />;
    }

    return children;
  }
}

export default ErrorBoundary;
