import './HighlightBanner.scss';

import React from 'react';

interface Props {
  children: string;
  backgroundColor?: string;
}

const HighlightBanner: React.FC<Props> = (props) => {
  const { children, backgroundColor } = props;

  return (
    <div
      className="HighlightBanner"
      style={{ backgroundColor: backgroundColor ? backgroundColor : undefined }}
    >
      {children}
    </div>
  );
};

export default HighlightBanner;
