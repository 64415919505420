import React from 'react';
import ReactDocumentTitle from 'react-document-title';

interface Props {
  title?: string | null;
  children: React.ReactNode;
}

const DocumentTitle: React.FC<Props> = (props) => {
  const title =
    props.title != null && props.title.length > 0
      ? `${props.title} – MUSAIC`
      : 'MUSAIC – New World Symphony';

  return (
    <ReactDocumentTitle title={title}>
      <>{props.children}</>
    </ReactDocumentTitle>
  );
};

export default DocumentTitle;
