import './LiveEventHeader.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';

import HighlightBanner from 'components/core/HighlightBanner';

import { LiveEventHeaderQuery } from './__generated__/LiveEventHeaderQuery';

import liveEventHeaderImageUrl from 'assets/images/category-patterns/banners/live-events.svg';

const DEFAULT_IMAGE_URL =
  '//s3.amazonaws.com/nws-musaic-production/live_events/images/000/000/095/original/Education_Concert_Webcast_-_1920x1080.jpg?1558031794';

const LIVE_EVENT_HEADER_QUERY = gql`
  query LiveEventHeaderQuery {
    liveEventsSummary {
      currentTotalCount
      futureTotalCount
      pastTotalCount
    }

    futureLiveEvents(first: 1) {
      id
      title
      startDate
    }

    currentLiveEvents(first: 1) {
      id
      title
      description
      endDate
      imageUrl
    }
  }
`;

const LiveEventHeader: React.FC = () => {
  return (
    <section>
      <Query<LiveEventHeaderQuery> query={LIVE_EVENT_HEADER_QUERY}>
        {({ data, loading }) => {
          if (loading || data == null || data.futureLiveEvents == null) {
            return null;
          }

          if (data.currentLiveEvents.length > 0) {
            return (
              <div
                className="LiveEventHeader-currentEvent"
                style={{
                  backgroundImage: `url('${
                    data.currentLiveEvents[0].imageUrl || DEFAULT_IMAGE_URL
                  }')`
                }}
              >
                <div className="LiveEventHeader-currentEvent-infoBar">
                  <div className="LiveEventHeader-currentEvent-infoBar-title">
                    {data.currentLiveEvents[0].title}
                  </div>
                  {data.currentLiveEvents[0].description != null ? (
                    <div
                      className="LiveEventHeader-currentEvent-infoBar-description"
                      dangerouslySetInnerHTML={{
                        __html: data.currentLiveEvents[0].description
                      }}
                    ></div>
                  ) : (
                    <div></div>
                  )}
                  <Link
                    to={`/live-events/${data.currentLiveEvents[0].id}`}
                    target="_blank"
                    className="LiveEventHeader-currentEvent-infoBar-link"
                  >
                    Watch Now
                  </Link>
                </div>
                <HighlightBanner backgroundColor="#6F62E5">
                  Live Now
                </HighlightBanner>
              </div>
            );
          }

          if (data.futureLiveEvents.length > 0) {
            return (
              <div
                className="LiveEventHeader-upcomingEvent"
                style={{
                  backgroundImage: `url('${liveEventHeaderImageUrl}')`
                }}
              >
                <div className="LiveEventHeader-upcomingEvent-info">
                  Upcoming Event — {data.futureLiveEvents[0].title} (
                  {DateTime.fromISO(
                    data.futureLiveEvents[0].startDate
                  ).toFormat('MMMM d, t ZZZZ')}
                  )
                </div>
                <Link
                  to={`/live-events/${data.futureLiveEvents[0].id}.ics`}
                  target="_blank"
                  className="LiveEventHeader-upcomingEvent-calendarLink"
                >
                  ＋ Add to Calendar
                </Link>
              </div>
            );
          }

          return null;
        }}
      </Query>
    </section>
  );
};

export default LiveEventHeader;
