import './CategoryTile.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import * as Core from 'components/core';

import orchestralExcerptsImage from 'assets/images/category-patterns/badges/orchestral-excerpts-badge.svg';
import masterClassesImage from 'assets/images/category-patterns/badges/master-classes-badge.svg';
import auditionPreparationImage from 'assets/images/category-patterns/badges/audition-preparation-badge.svg';
import reflectionsImage from 'assets/images/category-patterns/badges/reflections-badge.svg';
import performancesImage from 'assets/images/category-patterns/badges/performances-badge.svg';
import howToImage from 'assets/images/category-patterns/badges/how-to-badge.svg';
import liveEventsImage from 'assets/images/category-patterns/badges/live-events-badge.svg';

interface Props {
  to: string;
  categoryOverride?: string;
  badgeOverride?: string;
  categoryName?: string;
  inverted?: boolean;
}

export const defaultProps = {
  inverted: false
};

const CategoryTile: React.FC<Props> = (props) => {
  const { categoryName, to, categoryOverride, inverted, badgeOverride } = props;

  return (
    <Link
      to={to}
      className={classNames('CategoryTile', {
        'CategoryTile--inverted': inverted
      })}
      style={{
        backgroundImage:
          categoryName != null && TILE_IMAGES[categoryName] != null
            ? `url(${TILE_IMAGES[categoryName].pattern})`
            : undefined
      }}
    >
      <div className="CategoryTile-liner">
        {categoryName && <Core.CategoryBadge category={categoryName} />}
        {badgeOverride && <Core.Badge content={badgeOverride} />}

        <div className="CategoryTile-label">
          {categoryName ? categoryName : categoryOverride}
        </div>
      </div>
    </Link>
  );
};

const TILE_IMAGES: Record<string, { pattern: string }> = {
  'Orchestral Excerpts': {
    pattern: orchestralExcerptsImage
  },
  'Master Classes': {
    pattern: masterClassesImage
  },
  'Audition Preparation': {
    pattern: auditionPreparationImage
  },
  Reflections: {
    pattern: reflectionsImage
  },
  Performances: {
    pattern: performancesImage
  },
  'How-To': {
    pattern: howToImage
  },
  'Live Events': {
    pattern: liveEventsImage
  }
};

export default CategoryTile;
