import { css } from 'styled-components';

export default css`
  color: #ffffff;
  background-color: #007bff;
  border-color: #007bff;

  :focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  }

  :hover {
    background-color: #0069d9;
    border-color: #0062cc;
  }

  :disabled {
    background-color: #007bff;
    border-color: #007bff;
  }
`;
