import styled from 'styled-components';

import buttonCSS from './styles/buttonCss';
import primaryButtonCSS from './styles/primaryButtonCss';

type HTMLAnchorElementProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

interface Props extends HTMLAnchorElementProps {
  appearance?: 'button' | 'primaryButton';
}

export default styled.a`
  ${(props: Props) =>
    props.appearance === 'button' || props.appearance === 'primaryButton'
      ? buttonCSS
      : null}
  ${(props: Props) =>
    props.appearance === 'primaryButton' ? primaryButtonCSS : null}
`;
