import React from 'react';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import Select, { Props as SelectProps } from 'react-select/async';

import {
  ComposersSearchQuery,
  ComposersSearchQueryVariables
} from './__generated__/ComposersSearchQuery';

const COMPOSER_SEARCH_QUERY = gql`
  query ComposersSearchQuery($searchTerm: String!) {
    composers(searchTerm: $searchTerm) {
      id
      firstname
      lastname
    }
  }
`;

interface Props<IsMulti extends boolean>
  extends Omit<
    SelectProps<{ value: number; label: string }, IsMulti>,
    'loadOptions'
  > {
  onChange(composerIds: number[]): void;
}

const SearchComposersSelect = <IsMulti extends boolean>(
  props: Props<IsMulti>
) => {
  const { onChange, ...selectProps } = props;

  return (
    <Query<ComposersSearchQuery, ComposersSearchQueryVariables>
      query={COMPOSER_SEARCH_QUERY}
    >
      {({ refetch }) => {
        return (
          <Select
            noOptionsMessage={() => 'Start typing to see results'}
            {...selectProps}
            loadOptions={(inputValue) => {
              return refetch({ searchTerm: inputValue }).then(({ data }) => {
                if (data == null) {
                  return [];
                }

                return data.composers.map((composer) => ({
                  value: composer.id,
                  label: `${composer.lastname}, ${composer.firstname}`
                }));
              });
            }}
            onChange={(
              options:
                | { value: number }
                | Array<{ value: number }>
                | null
                | undefined
            ) => {
              if (Array.isArray(options)) {
                onChange(options.map((option) => option.value));
              } else if (options != null) {
                onChange([options.value]);
              } else {
                onChange([]);
              }
            }}
          />
        );
      }}
    </Query>
  );
};

export default SearchComposersSelect;
