import './Hero.scss';

import React from 'react';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';

import { TilesQuery } from './__generated__/TilesQuery';

import HeroMobile from './HeroMobile';
import HeroDesktop from './HeroDesktop';

const TILES_QUERY = gql`
  query TilesQuery {
    tiles {
      id

      video {
        id
        slug
        title
        thumbnailUrl

        category {
          id
          name
        }

        artists {
          id
          firstName
          lastName

          instruments {
            id
            name
          }
        }
      }
    }
  }
`;

const Hero: React.FC = () => {
  return (
    <Query<TilesQuery> query={TILES_QUERY}>
      {({ data, loading }) => {
        if (loading || data?.tiles == null) {
          return null;
        }

        return (
          <>
            <div className="HomeHero-mobile">
              <HeroMobile tiles={data.tiles} />
            </div>
            <div className="HomeHero-desktop">
              <HeroDesktop tiles={data.tiles} />
            </div>
          </>
        );
      }}
    </Query>
  );
};

export default Hero;
