import 'whatwg-fetch'; // apollo-link-http requires fetch

import { ApolloClient, InMemoryCache } from '@apollo/client';

const cache = new InMemoryCache();

export default new ApolloClient({
  uri: '/graphql',
  cache
});
