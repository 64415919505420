import React from 'react';

import Badge from './Badge';

interface Props {
  category: string;
  shadow?: true;
  inverted?: true;
}

const categoryAttributes = {
  'Live Events': {
    abbreviation: 'LE',
    colorOne: '#4097FF',
    colorTwo: '#7B60FF'
  },
  'Master Classes': {
    abbreviation: 'MC',
    colorOne: '#C970AD',
    colorTwo: '#8861AA'
  },
  'Audition Preparation': {
    abbreviation: 'AP',
    colorOne: '#F9A74F',
    colorTwo: '#F065A5'
  },
  Reflections: {
    abbreviation: 'R',
    colorOne: '#19C1F3',
    colorTwo: '#009EE1'
  },
  Performances: {
    abbreviation: 'P',
    colorOne: '#8FC73E',
    colorTwo: '#02B29D'
  },
  'How-To': {
    abbreviation: 'HT',
    colorOne: '#FDB813',
    colorTwo: '#F58840'
  },
  'Orchestral Excerpts': {
    abbreviation: 'OE',
    colorOne: '#F59AA3',
    colorTwo: '#F05759'
  }
};

const CategoryBadge: React.FC<Props> = (props) => {
  const { category } = props;

  const attributes = categoryAttributes[category] || {
    abbreviation: category.length > 0 ? category[0] : ''
  };

  return (
    <Badge
      content={attributes.abbreviation}
      shadow={props.shadow}
      inverted={props.inverted}
      colorOne={attributes.colorOne}
      colorTwo={attributes.colorTwo}
    />
  );
};

export default CategoryBadge;
