import './Header.scss';

import React, { useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Query } from '@apollo/client/react/components';

import UserMenu from './UserMenu';
import Logo from './Logo';
import SearchIcon from './icons/Search';

import {
  CURRENT_USER_QUERY,
  CurrentUserQuery
} from './queries/CurrentUserQuery';

const Header: React.FC = () => {
  const inputElement = useRef<HTMLInputElement | null>(null);

  const navigate = useNavigate();

  const focusInput = useCallback(() => {
    if (inputElement.current != null) {
      inputElement.current.focus();
    }
  }, [inputElement]);

  return (
    <Query<CurrentUserQuery> query={CURRENT_USER_QUERY}>
      {({ data }) => (
        <header className="Header" role="navigation">
          <div className="Header-main">
            <Link to="/">
              <Logo />
            </Link>

            <nav className="Header-main-nav">
              <Link
                className="Header-main-nav-link"
                to="/search?orderField=created_at&amp;orderDirection=desc"
              >
                Catalog
              </Link>
              <Link className="Header-main-nav-link" to="/pages/about">
                About
              </Link>

              {data != null && data.currentUser != null ? (
                <UserMenu user={data.currentUser} />
              ) : (
                <Link className="Header-main-nav-link" to="/sign-in">
                  Sign In
                </Link>
              )}

              <button onClick={focusInput} className="Header-searchButton">
                <SearchIcon />
              </button>
            </nav>
          </div>

          <div className="Header-searchInput-wrapper">
            <input
              ref={inputElement}
              type="text"
              autoComplete="off"
              placeholder="Search videos by artist, instrument, or composer"
              className="Header-searchInput"
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  navigate(
                    `/search?query=${encodeURIComponent(
                      event.currentTarget.value
                    )}`
                  );
                }
              }}
            />
            <div className="Header-searchArrow"></div>
          </div>
        </header>
      )}
    </Query>
  );
};

export default Header;
