import './Badge.scss';

import React from 'react';
import classNames from 'classnames';

interface Props {
  content: string;
  shadow?: true;
  inverted?: true;
  colorOne?: string;
  colorTwo?: string;
}

const Badge: React.FC<Props> = (props) => {
  const { content, shadow, inverted, colorOne, colorTwo } = props;

  return (
    <div
      className={classNames('Badge', {
        'Badge--withShadow': shadow,
        'Badge--inverted': inverted
      })}
    >
      <div className="Badge-main">{content}</div>
      {shadow && (
        <>
          <div
            className="Badge-shadowOne"
            style={colorOne ? { backgroundColor: colorOne } : undefined}
          ></div>
          <div
            className="Badge-shadowTwo"
            style={colorTwo ? { backgroundColor: colorTwo } : undefined}
          ></div>
        </>
      )}
    </div>
  );
};

export default Badge;
