import './Footer.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';

import Logo from './Logo';
import * as Core from 'components/core';
import UserMenu from './UserMenu';

import logoImage from 'assets/images/partner-logos/new-world-symphony.png';

import {
  CURRENT_USER_QUERY,
  CurrentUserQuery
} from './queries/CurrentUserQuery';

interface Props {}

const Footer: React.FC<Props> = () => {
  return (
    <Query<CurrentUserQuery> query={CURRENT_USER_QUERY}>
      {({ data }) => (
        <Core.PageContainer>
          <footer className="Footer">
            <div className="Footer-content">
              <nav className="Footer-content-nav">
                <Link to="/">
                  <Logo />
                </Link>

                <div>
                  <Link to="/search?orderField=created_at&amp;orderDirection=desc">
                    Catalog
                  </Link>
                  <br />
                  <Link to="/artists">All Artists</Link>
                  <br />
                  <Link to="/pages/about">About</Link>
                </div>

                <div>
                  {data != null && data.currentUser != null ? (
                    <UserMenu user={data.currentUser} />
                  ) : (
                    <Link to="/sign-in">Sign In</Link>
                  )}
                </div>
              </nav>

              <Link to="/" className="Footer-content-nwsLink">
                <img height="67px" src={logoImage} alt="New World Symphony" />
              </Link>
            </div>

            <div className="Footer-signoff">
              © {new Date().getFullYear()} New World Symphony
            </div>
          </footer>
        </Core.PageContainer>
      )}
    </Query>
  );
};

export default Footer;
