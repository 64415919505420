import './ErrorBoundaryMessage.scss';

import React from 'react';

import PageContainer from './PageContainer';

interface Props {
  error?: Error;
}

/*
 * This is rendered if an Error is thrown within either
 * <FallbackErrorBoundary /> or <BugsnagErrorBoundary />.
 */
const ErrorBoundaryMessage: React.FC<Props> = (props) => {
  if (props.error == null) {
    return null;
  }

  return (
    <PageContainer>
      <div className="ErrorBoundaryMessage">
        <div>
          <h1>An error has occurred.</h1>

          <p>We’re sorry, but something has gone wrong.</p>

          <p>
            Head back <a href="/">home</a>.
          </p>
        </div>
      </div>
    </PageContainer>
  );
};

export default ErrorBoundaryMessage;
