import './HeroDesktop.scss';

import React from 'react';
import { Link } from 'react-router-dom';

import { TilesQuery_tiles } from './__generated__/TilesQuery';
import * as Core from 'components/core';

interface Props {
  tiles: TilesQuery_tiles[];
}

const HeroDesktop: React.FC<Props> = (props) => {
  const firstTile = props.tiles ? props.tiles[0] : null;
  const additionalTiles = props.tiles?.slice(1, 4);

  if (firstTile?.video == null || additionalTiles == null) {
    return null;
  }

  return (
    <div className="HomeHeroDesktop-wrapper">
      <section className="HomeHeroDesktop">
        <Link
          className="HomeHeroDesktop-firstTile"
          key={firstTile.id}
          to={`/videos/${firstTile.video.slug}`}
          style={{
            backgroundImage: `url(${firstTile.video.thumbnailUrl})`
          }}
        >
          <div className="HomeHeroDesktop-firstTile-infoBar">
            <div className="HomeHeroDesktop-firstTile-infoBar-title">
              {firstTile.video.title}
            </div>
            {firstTile.video.artists.map((artist) => (
              <div key={artist.id}>
                {artist.firstName} {artist.lastName}
                {artist.instruments.length > 0 &&
                  `, ${artist.instruments[0].name}`}
              </div>
            ))}
          </div>
          {firstTile.video.category != null && (
            <div className="HomeHeroDesktop-firstTile-category">
              <Core.CategoryBadge
                category={firstTile.video.category.name}
                shadow
              />
            </div>
          )}
          <Core.HighlightBanner>Featured Video</Core.HighlightBanner>
        </Link>

        <div className="HomeHeroDesktop-additionalTiles">
          {additionalTiles.map((tile) => (
            <React.Fragment key={tile.id}>
              {tile.video != null && (
                <Link
                  key={tile.id}
                  to={`/videos/${tile.video.slug}`}
                  className="HomeHeroDesktop-additionalTiles-tile"
                  style={{
                    backgroundImage: `url(${tile.video.thumbnailUrl})`
                  }}
                >
                  <div className="HomeHeroDesktop-additionalTiles-tile-infoBar">
                    <div className="HomeHeroDesktop-additionalTiles-tile-infoBar-title">
                      {tile.video.title} {tile.video.category?.name}
                    </div>
                    {tile.video.artists.map((artist) => (
                      <div key={artist.id}>
                        {artist.firstName} {artist.lastName}
                        {artist.instruments.length > 0 &&
                          `, ${artist.instruments[0].name}`}
                      </div>
                    ))}
                  </div>
                  {tile.video.category != null && (
                    <div className="HomeHeroDesktop-additionalTiles-tile-category">
                      <Core.CategoryBadge
                        category={tile.video.category.name}
                        shadow
                      />
                    </div>
                  )}
                </Link>
              )}
            </React.Fragment>
          ))}
        </div>
      </section>
    </div>
  );
};

export default HeroDesktop;
