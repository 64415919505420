import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import * as Core from 'components/core';
import Notifications, { NotificationsProvider } from './Notifications';
import Header from './Header';
import Footer from './Footer';
import Home from './home';

const Search = React.lazy(() => import('./search/Search'));
const NewVideo = React.lazy(() => import('./videos/New'));
const Video = React.lazy(() => import('./videos/Single'));
const Register = React.lazy(() => import('./Register'));
const Passwords = React.lazy(() => import('./passwords'));
const SignIn = React.lazy(() => import('./SignIn'));
const Account = React.lazy(() => import('./Account'));
const Playlists = React.lazy(() => import('./playlists/All'));
const Playlist = React.lazy(() => import('./playlists/Single'));
const PlaylistEdit = React.lazy(() => import('./playlists/Edit'));
const LiveEvent = React.lazy(() => import('./live-events/Single'));
const Artists = React.lazy(() => import('./artists/All'));
const Partner = React.lazy(() => import('./partners/Single'));
const PartnerVideos = React.lazy(() => import('./partners/Videos'));
const Page = React.lazy(() => import('./pages/Single'));
const About = React.lazy(() => import('./AboutPage'));

const Application: React.FC = () => {
  return (
    <Core.BugsnagErrorBoundary>
      <NotificationsProvider>
        <BrowserRouter>
          <Core.DocumentTitle>
            <Header />

            <Notifications />

            <main>
              <React.Suspense fallback={<Core.Loader />}>
                <Core.BugsnagErrorBoundary>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/search" element={<Search />} />
                    <Route path="/videos/new" element={<NewVideo />} />
                    <Route path="/videos/:slug" element={<Video />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/reset-password" element={<Passwords />} />
                    <Route path="/sign-in" element={<SignIn />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/playlists" element={<Playlists />} />
                    <Route path="/playlists/:id" element={<Playlist />} />
                    <Route
                      path="/playlists/:id/edit"
                      element={<PlaylistEdit />}
                    />
                    <Route path="/live-events/:id" element={<LiveEvent />} />
                    <Route path="/artists" element={<Artists />} />
                    <Route path="/partners/:id" element={<Partner />} />
                    <Route
                      path="/partners/:id/videos"
                      element={<PartnerVideos />}
                    />
                    <Route path="/pages/about" element={<About />} />
                    <Route path="/pages/:slug" element={<Page />} />

                    <Route path="*" element={<Core.NotFound />} />
                  </Routes>
                </Core.BugsnagErrorBoundary>
              </React.Suspense>
            </main>

            <Footer />
          </Core.DocumentTitle>
        </BrowserRouter>
      </NotificationsProvider>
    </Core.BugsnagErrorBoundary>
  );
};

export default Application;
