import React from 'react';
import { uniqueId } from 'lodash-es';

type HTMLInputElementProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export interface Props extends HTMLInputElementProps {
  label: string;
}

class Checkbox extends React.PureComponent<Props> {
  inputId = uniqueId('input');

  render() {
    const { label, ...inputProps } = this.props;
    return (
      <div>
        <input {...inputProps} id={this.inputId} type="checkbox" />
        <label htmlFor={this.inputId}>{label}</label>
      </div>
    );
  }
}

export default Checkbox;
