import './HeroMobile.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import * as Core from 'components/core';
import { TilesQuery_tiles } from './__generated__/TilesQuery';

interface Props {
  tiles: TilesQuery_tiles[];
}

const HeroMobile: React.FC<Props> = (props) => {
  const { tiles } = props;

  return (
    <div className="HomeHeroMobile">
      <CarouselProvider
        naturalSlideWidth={85}
        naturalSlideHeight={50}
        totalSlides={props.tiles.length}
      >
        <Slider>
          {tiles.map((tile, index) => (
            <Slide index={index} key={index}>
              {tile.video != null && (
                <Link
                  key={tile.id}
                  to={`/videos/${tile.video.slug}`}
                  className="HomeHeroMobile-tile"
                  style={{
                    backgroundImage: `url(${tile.video.thumbnailUrl})`
                  }}
                >
                  <div className="HomeHeroMobile-tile-infoBar">
                    <div className="HomeHeroMobile-tile-infoBar-title">
                      {tile.video.title} {tile.video.category?.name}
                    </div>
                    {tile.video.artists.map((artist) => (
                      <div key={artist.id}>
                        {artist.firstName} {artist.lastName}
                        {artist.instruments.length > 0 &&
                          `, ${artist.instruments[0].name}`}
                      </div>
                    ))}
                  </div>
                  {tile.video.category != null && (
                    <div className="HomeHeroMobile-tile-category">
                      <Core.CategoryBadge
                        category={tile.video.category.name}
                        shadow
                      />
                    </div>
                  )}
                </Link>
              )}
            </Slide>
          ))}
        </Slider>
      </CarouselProvider>
    </div>
  );
};

export default HeroMobile;
