import React from 'react';

export interface Props {
  size: string;
}

export const defaultProps = {
  size: '27px'
};

class SearchIcon extends React.PureComponent<Props> {
  static defaultProps = defaultProps;

  render() {
    return (
      <svg
        width={this.props.size}
        height={this.props.size}
        viewBox="0 0 27 27"
        version="1.1"
      >
        <g fill="none" fillRule="evenodd">
          <g>
            <path
              d="M23.25,21 L22.065,21 L21.645,20.595 C23.115,18.885 24,16.665 24,14.25 C24,8.865 19.635,4.5 14.25,4.5 C8.865,4.5 4.5,8.865 4.5,14.25 C4.5,19.635 8.865,24 14.25,24 C16.665,24 18.885,23.115 20.595,21.645 L21,22.065 L21,23.25 L28.5,30.735 L30.735,28.5 L23.25,21 Z M14.25,21 C10.515,21 7.5,17.985 7.5,14.25 C7.5,10.515 10.515,7.5 14.25,7.5 C17.985,7.5 21,10.515 21,14.25 C21,17.985 17.985,21 14.25,21 Z"
              id="Shape"
              fill="#141B2E"
            ></path>
            <polygon id="Path" points="0 0 36 0 36 36 0 36"></polygon>
          </g>
        </g>
      </svg>
    );
  }
}

export default SearchIcon;
