import React, { useCallback, useEffect } from 'react';

/**
 * Calls `callback` when the user clicks on an element which is not referenced
 * by `refs` or a descendant of `refs`.
 */
const useClickOutside = (
  refs: React.RefObject<unknown>[],
  callback: () => void,
  active = true
) => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const { target } = event;

      if (
        target instanceof HTMLElement &&
        !refs.some(
          (ref) =>
            ref.current instanceof HTMLElement &&
            (ref.current === target || ref.current.contains(target))
        )
      ) {
        callback();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback, active, ...refs]
  );

  useEffect((): (() => void) | void => {
    if (active) {
      window.addEventListener('click', handleClickOutside, false);

      return () => {
        window.removeEventListener('click', handleClickOutside, false);
      };
    }
  }, [handleClickOutside, active]);
};

export default useClickOutside;
